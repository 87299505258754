@media (min-width: map-get($grid-breakpoints, ultralarge)) {
	.promotions {

		&__info {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&__category {
			position: absolute;
			top: 65px;
			right: 6rem;
		}
	}

	.promotions__item--type2 .promotions__category {
		top: 50px;
		right: auto;
	}

	.services__back {
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

@media (max-width: map-get($grid-breakpoints, xxl)) {
	.services {
		&__head-title {
			font-size: 5rem;
		}

		&__back {
			padding: 20px 35px 20px 20px;
		}

		&__category {
			right: 10px;
		}

		&__title {
			font-size: 1.3rem;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, xl)) {
	.services {
		&__head-title {
			font-size: 4rem;
		}

		&__back {
			padding: 10px 30px 10px 10px;
		}

		&__title {
			font-size: 1.2rem;
		}

		&__back-title {
			font-size: 1rem;
			margin-bottom: 5px;
		}

		&__description {
			font-size: 0.875rem;
			margin-bottom: 5px;
		}

		&__category {
			right: 5px;
		}
	}
}

@media (hover: hover) {
	.rev_slider_wrapper {

		.tp-bullet:hover {
			background: url('../images/slider-bullet-active.svg') no-repeat center;
		}
	}

	.header {
		&.active .header__button--menu:hover {
			i {
				background: $main-theme-color;
			}
		}

		&__button--menu:hover {
			i {
				background: $main-theme-color;
			}
		
			i:nth-of-type(2) {
				transition-duration: 200ms;
			}
		
			i:nth-of-type(3) {
				transition-duration: 400ms;
			}
		}
	}

	.main-nav {

		&__hidden-menu__item:hover {
			color: $header-menu-item-active-color;
		}

		&__label:hover {
			color: $header-menu-item-active-color;

			i:before,
			i:after {
				background-color: #244356;
			}
		}
	}

	.menu-language__item:hover {
		color: $main-theme-color;
	}

	.welcome__description a:hover {
		color: $main-theme-color;
	}

	.services__item:hover {
		.services__front {
			opacity: 0;
		}

		.services__back {
			z-index: 2;
		}
	}

	.services__item--no-image:hover {
		background-color: #fff;

		.services__back, .services__description {
			color: #000;
		}
	}
	
	.services__item--viewall:hover {
		background-color: #97a6ae;
	}

	.services--gallery .services__item:hover {
		.services__front {
			background-color: #fff;
			background-image: none !important;
			opacity: 1;

			&:before {
				display: none;
			}
		}
		.services__title {
			color: #182730;
		}
	}

	.blog {
		
		&__category a:hover {
			color: $main-theme-color;
		}

		&__item-title:hover {
			color: #557d95;
		}

		.date-component__link:hover {
			color: #2c6d94;
		}

		&__item--akcent {
			
			.blog__item-title:hover {
				color: #00bcc6;
			}
	
			.date-component__link:hover {
				color: #009ba4;
			}
		}
	}
	
	.date-component__link:hover {
		color: $main-theme-color;
	}

	.slick-dots > li:hover {
		background-image: url('../images/slider-bullet-active.svg');
	}

	.promotions {

		&__title:hover, &__link:hover {
			color: $secondary-text-color,
		}

		&__image:hover {
			img {
				transform: scale(1.1);
			}
		}
	}

	.breadcrumbs__item:hover {
		color: $thernary-text-color;
	}

	.breadcrumbs--type2 .breadcrumbs__item:hover {
		color: $main-theme-color;
	}

	.info-section {
		&__description a:hover {
			color: $main-theme-color;
		}

		&__back:hover {
			color: $main-theme-color;

			i {
				border-color: $main-theme-color;
				
                &:nth-of-type(3) {
                    border-color: #ebeff4;
                }
			}
		}

		&__filter-item:hover {
			color: $main-theme-color;
		}
	}

	.products {

		&__image:hover img {
			transform: scale(1.2);
		}

		&__title:hover {
			color: $main-theme-color;
		}

		&--type2 .products__item--akcent {
			.products__title:hover {
				color: #00bcc6;
			}
	
			.date-component__link:hover {
				color: #009ba4;
			}
		}
	}

	.btn:hover {
		background-color: $main-theme-color;
		color: #fff;
	}

	.inner-info__nav-item:hover {
		
		svg {
			fill: $main-theme-color;
		}

		span {
			opacity: 1;
		}
	}

	.inner-info__content a:hover, .inserted-file:hover {
		color: $main-theme-color;
	}

	.product-details {

		&__gallery a:hover {
			color: #fff;
			background-color: $main-theme-color;
			border-color: $main-theme-color;
		}

		&__text a:hover {
			color: $main-theme-color;
		}
	}
	

	.footer {

		&__navigation-item:hover {
			color: $footer-text-hover-color;
		}

		&__social-item:hover svg {
			fill: $footer-text-color;
		}

		&__gotop:hover {
			color: $footer-text-color;
		}

		&__allrights a:hover {
			color: $footer-text-color;
		}
	}
}

@media (min-width: map-get($grid-breakpoints, tablet)) {
	.services__item {
		&:nth-child(3n+1) {
            animation-delay: 200ms;

            .services__head-title, .services__title {
                animation-delay: 400ms;
            }
        }

        &:nth-child(3n+2) {
            animation-delay: 400ms;

            .services__title {
                animation-delay: 600ms;
            }
        }

        &:nth-child(3n+3) {
            animation-delay: 600ms;
            
            .services__title {
                animation-delay: 800ms;
            }
        }
	}

	.products__item {
		&:nth-child(3n+1) {
            animation-delay: 200ms;
        }

        &:nth-child(3n+2) {
            animation-delay: 400ms;
        }

        &:nth-child(3n+3) {
            animation-delay: 600ms;
        }
	}
}

@media (max-width: map-get($grid-breakpoints, tablet)) {
	html {
		font-size: 15px;
	}

	body.noScroll {

		.header__inner {
			padding-right: 65px;
		}

 		.header.fixed .header__inner {
			padding-right: 20px;
		}
	}

	.header {

		&__menu-top {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

	.main-nav__label {
		font-size: 1.5rem;
	}

	.promotions {

		&__category {
			padding-right: 3rem;
		}

		&__info {
			padding-left: 3rem;
			padding-right: 3rem;
		}

		&__item--type2, &__item--type4 {
			.promotions__info {
				padding-left: 3rem;
			}

			.promotions__category {
				padding-left: 4rem;
			}
		}
	}

	.products--type2 .products__item {
		padding: 0 20px;
	}

	.blog {
		margin-top: 60px;
	}

	.form {

		&__input,
		&__textarea {
			-webkit-appearance: none;
		}
	}
}


@media (max-width: map-get($grid-breakpoints, lg)) {
	.wrapper {
		padding-left: 30px;
		padding-right: 30px;
	}

	.hamburger-icon {
		transform: scale(0.55);
	}

	body.noScroll .header__inner {
		padding-right: 20px;
	}

	.header {

		&.fixed {
			.header__logo {
				width: 130px;
				height: 33px;
			}
			
			.header__inner {
				padding: 10px 20px;
			}
		} 

		&.active  {
			.header__button {
				background-color: #fff;
				
				span {
					display: none;
				}
			}

		}

		&__inner {
			padding: 10px 20px;
		}

		&__logo {
			height: 33px;
			width: 130px;
		}

		&__menu {
			background: #fff;

			&-top {
				padding: 10px 30px;
			}

			&-logo {
				width: 35px;
				height: 35px;
				margin-right: 40px;
			}
		}

		&__menu-nav {
			flex-direction: column;
		}
	}

	.menu-language__item {
		font-size: 14px;
		margin: 5px;
	}

	.main-nav {
		width: 100%;
		padding: 0 30px 30px 30px;

		&--secondary {
			background-color: #f0f3f4;
			padding-bottom: 50px;

			.main-nav {
				&__label {
					font-size: 15px;
				}
			}
		}

		&__item {
			margin-top: 30px;
		}

		&__label {
			font-size: 15px;
		}

		&__expand {
			min-width: 20px;
			height: 20px;
		}

		&__hidden-menu {
			padding-left: 30px;

			&__item {
				font-size: 14px;
				margin: 20px 20px 0 0
			}
		}
	}

	.rev_slider_wrapper {

		&--innerpage .slotholder {
			height: 100vh;
			min-height: 500px;
		}

		.section-shape {
			display: none;
		}

		.tp-bullets {
			top: auto !important;
			left: 50% !important;
			bottom: 30px;
			transform: rotate(-90deg) translateX(-50%) !important;
			margin-left: -15px;
		}
		
		.tp-bullet {
			transform: rotate(90deg);
		}

		.main_caption_name {
			font-size: 28px !important;
			line-height: 1.3 !important;
			padding-right: 25px !important;

			&--subtitle {
				font-size: 20px !important;
			}
		}
	} 

	.welcome {
		padding: 40px 0;
		
		&__logo {
			width: 80px;
			height: 80px;
			margin-left: auto;
			margin-right: auto;
		}

		&__title {
			font-size: 36px;
			padding: 0;
			text-align: center;
			
			span {
				font-size: 16px;
				padding-left: 80px;
			}
		}

		&__description {
			padding-left: 0;
			margin-top: 45px;
		}

		.section-shape {

			&--left {
				top: 60px;
				
				height: 140px;
				width: 100px;
			}

			&--right {
				display: none;
			}
		}
	}

	.services {
		padding: 0 30px 10px 30px;

		&__item {
			width: calc(50% - 10px);
			padding-bottom: calc(50% - 30px);
		}

		&__title {
			font-size: 20px;
		}
	}

	.main-paralax {
		padding: 30px;
	}

	.banner-wrapper .main-paralax:before {
		display: none;
	}

	.banner {
		padding: 40px 0;

		&-wrapper__shape {
			display: none;

			&--bottom {
				display: none;
			}

			&--mobile {
				left: auto;
				right: 0;
				transform: scaleX(-1);
				top: 350px;
				width: 145px;
				height: 290px;
				display: block
			}
		}

		&__shape {
			display: none;

			&--mobile {
				top: -6px;
				width: 260px;
				height: 270px;
				display: block;
			}
		}

		&__image {
			height: 550px;
		}

		&__text {
			font-size: 44px;
			left: 30px;
			bottom: -3px;
		}

		&__description {
			position: static;
			padding: 70px 30px 30px 30px;
			max-width: 100%;

			position: relative;
			left: 0;
			bottom: auto;

			&:before {
				display: none;
			}

			&:after {
				// top: calc(100% - 40px);
				top: 100%;
				padding-bottom: 30%;
				height: auto;
			}
		}	
	}

	.promotions {
		padding: 0;

		&__item {
			flex-direction: column;

			&--type2, &--type4 {
				.promotions {

					&__info {
						order: 3;
					}

					&__category {
						padding-left: 0;
						text-align: right;
					}
				}
			}
		}

		&__image {
			width: 100%;
			padding: 0 30px;
			margin: 0;
			z-index: 2;
		}

		&__info {
			width: 100%;
			margin: -35px 0 30px 0;
			padding: 55px 30px 30px 30px;
		}

		&__shape {
			display: none;
		}

		&__category {
			padding-right: 0;
			font-size: 16px;
		}

		&__title {
			font-size: 22px;
		}

		&__link {
			display: inline-block;
			margin-bottom: 10px;
		}
	}
		
	.blog {
		padding: 80px 30px;
		overflow: hidden;

		&__shape {
			top: 15px;
			right: -80px;
			width: 160px;
			height: 160px;
		}

		&__title {
			font-size: 38px;
			padding-right: 0;
		}

		&__category {
			padding-right: 0;
		}

		&__item {
			padding: 0;
		}

		&__item-title {
			font-size: 20px;
		}
	}

	.date-component {

		&__date {
			font-size: 85px;

			span {
				font-size: 19px;
			}
		}
	}

	.breadcrumbs {
		padding: 10px 0;

		&:after {
			display: none;
		}

		&__item:first-child {
			margin-left: 0;
		}
	}

	.inner-info {

		&__nav {
			margin: 40px 0 20px 0;

			&-item span {
				display: none;
			}
		}
		
		&__content {
			
			h1 {
				font-size: 22px;
				margin-bottom: 20px;
			}

			h2,h3,h4,h5,h6 {
				font-size: 20px;
			}
		}

		&__date {
			margin-top: 0;
			font-size: 36px;
			padding-right: 20px;

			& + & {
				padding-left: 20px;
			}

			span {
				margin: 5px 0 0 0px;
			}
		}
	}

	.gallery-block {
		padding: 40px 0;
	}

	.product-details {

		&__top {
			flex-direction: column;
			margin-bottom: 0;
		}

		&__brand {
			order: 2;
		}

		&__image {
			float: none;
			margin: 0 0 30px 0;
		}

		&__category {
			font-size: 20px;
			margin-bottom: 30px;
		}

		&__gallery a {
			font-size: 16px;
		}

		&__title {
			font-size: 22px;
			margin-bottom: 20px;
		}

		&__text {
			h2,h3,h4,h5,h6 {
				font-size: 20px;
			}

		}

		&__bottom {
			flex-direction: column;
			margin-bottom: 30px;
		}
	}

	.btn {
		width: 100%;
		text-align: center;
		padding: 20px;
	}

	.info-section {
		padding: 50px 0 30px 0;

		&__filter {
			margin: 40px 0 20px 0;

			&-item {
				font-size: 16px;
				margin: 5px 10px;
			}
		}

		&__back {
			position: static;
			padding: 0 30px;
			justify-content: flex-end;
			margin-bottom: 30px;
		}

		&__shape {
			top: 20px;
			left: -10px;
			width: 70px;
			height: 70px;
			bottom: auto;

			&--inner {
				top: 0;
			}

			&--center {
				height: 100px;
				left: -5px;
				top: 30px;
			}

			&--center-text {
				margin-top: 0;
			}

			&--type5 {
				top: -75px;
			}
		}

		&__title {
			font-size: 22px;
			padding-left: 0;
			margin-bottom: 15px;
			margin-top: 50px;
		}

		&__description {
			padding-left: 0;

			&--two-columns {
				flex-wrap: wrap;
			}
		}

		&__col {
			width: 100%;
			padding: 0;

			&:last-child {
				padding-left: 0;
				border-left: none;

				article {
					margin-top: 20px;
				}
			}

			article + article {
				margin-top: 20px;
			}
		}
	}

	.products {

		&__item {
			width: 50%;
		}

		&--type2 {
			padding-left: 30px;
			padding-right: 30px;
			
			.products__bottom {
				padding-right: 40px;
			} 

			.products__item {
				margin-bottom: 75px;
			}
		}
	}

	.footer {

		&__left {
			padding-left: 2.2rem;
		}

		&__title {
			font-size: 3.5rem;
		}

		&__social {
			margin-top: 30px;
			width: 100%;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, md)) {

	.rev_slider_wrapper {

		.tp-caption {
			min-width: 300px !important;
		}
	}

	.footer {
		flex-direction: column;

		&__left, &__right {
			width: 100%;
			padding: 0 30px;
		}

		&__title {
			font-size: 44px;
		}

		&__info {
			font-size: 15px;
			padding: 40px 0;
		}

		&__right {
			padding-top: 25px;
			padding-bottom: 110px;
			flex-direction: column;
		}

		&__navigation {
			width: 100%;

			&-group {
				padding-right: 25px;
			}

			&-item {
				font-size: 12px;
				margin-top: 12px;
				margin-bottom: 12px;
			}
		}

		&__social {
			width: 100%;
			justify-content: center;

			&-item {
				width: 33px;
				height: 33px;
				margin: 0 20px;
			}
		}

		&__allrights {
			width: 100%;
			margin-top: 35px;
			text-align: center;
		}

		&__gotop {
			right: 35px;
			bottom: 65px;
		}

		&__shape {
			width: 110px;
			height: 44px;
			right: 10px;
		}
	}

	.products {
		padding: 20px 25px;

		&--type2 .products {

			&__item {
				padding: 0;
				margin-bottom: 50px;
			}

			&__bottom {
				padding-right: 0;
			}
		}

		&__item {
			width: 100%;
		}

		&__category {
			font-size: 18px;
			padding-left: 10px;
		}

		&__title, &__description {
			padding: 0 10px;
		}

		&__title {
			font-size: 20px;
		}

		&__description {
			padding-right: 0;
			margin-bottom: 50px;
		}
	}

	.date-component__date {
		font-size: 66px;

		span {
			top: 20px;
			right: -30px;
			font-size: 17px;
		}
	}

	.contacts {
		padding: 50px 0;

		&__form {
			margin: 25px 0 0 0;
		}

		&__title {
			font-size: 1.5rem;
			padding: 0;
			margin-bottom: 20px;
		}

		&__form {
			margin: 0;
		}

		&__text {
			margin-top: 25px;
		}
	}

	.form {

		&__row {

			&--two-columns {
				display: block;
			}

			&--buttons {
				padding-left: 0;
			}

			.btn, .gcaptcha {
				margin-top: 20px;
			}
		}

		&__col {
			padding: 0;

			&-50 {
				width: 100%;

				&+& {
					margin-top: 15px;
				}
			}
		}

		&__input {
			font-size: 14px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, sm)) {
	.rev_slider_wrapper {

		.main_caption_name {
			font-size: 26px !important;
		}
	} 

	.services {
		
		&__item {
			width: 100%;
			padding-bottom: 100%;
			margin: 0 0 10px 0;
		}
	}

	.form {

		&__row {
			&--buttons {

				.form__col {
					flex-wrap: wrap;
				}
			}
		}

		&__checkbox + &__label {
			margin-left: 0;
		}
	}
}