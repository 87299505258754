* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    transition: all .2s ease;
    box-sizing: border-box;
}

html, body {
    &.noScroll {
        position: relative;
        overflow: hidden;
    }
}

html {
    height: 100%;
    font-size: $rem-baseline;
}

body {
    color: $main-text-color;
    line-height: 1.3;
    font-family: $font-montserrat;

    &.noScroll {
  
        .header__inner {
            padding-right: 79px;
        }

        .header.fixed .header__inner {
            padding-right: 40px;
        }
    } 
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    max-width: 100%;
}

svg {
    max-width: 100%;
    max-height: 100%;
}

@keyframes scrollDownPoint {
    0% {
        transform: translate(0, 0);
        opacity: 0; 
    }
    40% {
        opacity: 1;
    }
    80% {
        transform: translate(0, 20px);
        opacity: 0; 
    }
    100% {
        opacity: 0; 
    } 
}

.wrapper {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

#sliderMain {
    min-height: 1080px;

    * {
        transition: none;
    }
}

.section-shape {
    position: absolute;

    svg {
        width: 100%;
        height: 100%;
    }
}

.hamburger-icon {
    width: 60px;
    height: 45px;
    position: relative;
    transform: rotate(0deg);
    transition: none;
    transform: scale(0.65);
    cursor: pointer;

    i {
        display: block;
        position: absolute;
        height: 6px;
        width: 100%;
        background: #fff;
        border-radius: 0;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
            transform-origin: left center;
            background: $main-icons-default-color;
        }

        &:nth-child(2) {
            top: 18px;
            transform-origin: left center;
        }

        &:nth-child(3) {
            top: 36px;
            transform-origin: left center;
        }
    }

    &.active i {
        &:nth-child(1) {
            transform: rotate(45deg);
            top: -3px;
            left: 8px;
        }
          
         &:nth-child(2) {
            width: 0%;
            opacity: 0;
        }
          
         &:nth-child(3) {
            transform: rotate(-45deg);
            top: 39px;
            left: 8px;
            background: $main-theme-color;
        }
    }
}

.slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    > li {
        margin: 0 15px;
        background: url('../images/blog-slider-bullet.svg') no-repeat center;
        border: none;
        width: 30px;
        height: 30px;
        font-size: 0;
        cursor: pointer;

        &.slick-active {
            background-image: url('../images/slider-bullet-active.svg');
        }
        
        > button {
            display: none;
        }
    }
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
   
    &.fixed {
        background-color: $header-sticky-background-color;

        .header {

            &__logo {
                width: 140px;
                height: 35px;
            }

            &__inner {
                padding: 10px 25px 10px;

            }

            &__button {
                flex-direction: row;

                .hamburger-icon {
                    order: 2;
                    transform: scale(0.55);
                }

                span {
                    margin-left: 0;
                }
            }
        }
    }

    &.active {

        .header__button {
            background: #f0f3f4;

            i {
                background: #185478;
            }
            
            span {
                opacity: 0;
            }
        }
    }

    &.fixed.active .header__menu-top {
        padding-top: 10px;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 65px 10px;
        transition: none;
    }

    &__logo {
        width: 360px;
        height: 105px;

        &__icon {
            width: 100%;
            fill: #fff;
        }
    }

    &__button {
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 21;

        span {
            text-transform: uppercase;
            font-size: 0.75rem;
            color: $secondary-text-color;
        }
    }

    &__menu {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        min-height: 100vh;
        background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 64%, rgba(240,243,244,1) 64%, rgba(240,243,244,1) 100%);
        visibility: hidden;
        overflow-y:auto;
        opacity: 0;
        z-index: 20;
        
        &.active {
            opacity: 1;
            visibility: visible;

            .main-nav__item {
                opacity: 1;
            }
        }

        &-top {
            display: flex;
            align-items: center;
            padding: 55px 50px 40px 75px;
        }

        &-nav {
            display: flex;
            justify-content: center;
        }

        &-logo {
            width: 55px;
            height: 55px;
            margin-right: 75px;

            &-icon {
                fill: #185478;
            }
        }
    }
}

.rev_slider_wrapper {
    position: relative;
    z-index: 10;

    .slotholder {
        position: relative;

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0) 60%);
        }
    }

    .section-shape {
        z-index: 21;
        opacity: 0;
        transition: opacity 1s ease !important;

        &.visible {
            opacity: 1;
        }

        &--left {
            top: 0;
            left: 0;
            width: 800px;
            height: 430px;
        }

        &--right {
            bottom: 0;
            right: 0;
            width: 315px;
            height: 240px;
        }
    }

    .main_caption_name {
        position: relative;
        font-family: $font-montserrat;
        font-size: 4.375rem;
        font-weight: $font-bold;
        text-transform: uppercase;
        line-height: 1.1;
        color: $main-slider-text-color;

        &--subtitle {
            display: block;
            font-size: 2.1875rem;
            margin-top: 20px;
            text-transform: initial;
            font-weight: $font-regular;
        }
    }

    &__text-layer {
        color: $main-slider-text-color !important;
        text-transform: uppercase;
    }

    &__scroll-down {
        position: absolute;
        bottom: 25px;
        left: 0;
        right: 0;
        width: 1.875rem;
        height: 3.125rem;
        border: 2px solid $main-slider-text-color;
        border-radius: 3.125rem;
        margin: auto;
        cursor: pointer;
        z-index: 20;

        &:before {
            position: absolute;
            top: 10px;
            left: 50%;
            content: '';
            width: 6px;
            height: 6px;
            margin-left: -3px;
            background-color: $main-slider-text-color;
            border-radius: 100%;
            animation: scrollDownPoint 2s infinite;
        }
    }

    .tp-caption {
        white-space: inherit !important;
    }

    .tp-bullets {
        left: calc(100% - 30px) !important;
    }

    .tp-bullet {
        border-radius: 0;
        background: url('../images/slider-bullet.svg') no-repeat center;
        box-shadow: none;
        width: 25px;
        height: 25px;

        &:after {
            display: none;
        }

        &.selected {
            background: url('../images/slider-bullet-active.svg') no-repeat center;
        }
    }

    &--innerpage {
        .section-shape {
            opacity: 1;
        }

        .slotholder {
            height: 915px;
            background-size: cover;
        }
    }

    &--small .slotholder {
        height: 605px
    }
}

.main-nav {
    position: relative;
    display: flex;
    flex-flow: column;
    padding: 0 50px 0 75px;
    width: 64.5%;
    z-index: 22;

    &--secondary {  
        width: 35.5%;

        .main-nav {

            &__label {
                font-size: 1.625rem;
             }

            &__expand {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__item {
        font-family: $font-montserrat;
        margin-top: 2.5rem;
        opacity: 0;
        transition: 0.3s opacity 200ms;

        input {
            display: none;

            &:checked {
                & + span {
                    color: $header-menu-item-active-color;

                    i {

                        &:before {
                            background-color: #244356;
                        }

                        &:after {
                            opacity: 0;
                        }
                    }
                }

                & ~ div {
                    max-height: 600px;
                    transition: max-height 1.2s ease-in-out;
                }
            }
        }
    }

    &__label {
        color: $header-menu-item-color;
        text-transform: uppercase;
        text-align: left;
        font-size: 1.625rem;
        font-weight: $font-bold;
        color: #768893;
        display: inline-flex;
        align-items: center;
        transition: none;
    }

    &__expand {
        position: relative;
        display: inline-block;
        min-width: 25px;
        height: 25px;
        cursor: pointer;
        margin-left: 10px;

        &:before, &:after {
            position: absolute;
            content: '';
            background-color: #00c7d2;
            transition: opacity .3s ease;
        }

        &:before {
            width: 100%;
            height: 4px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &:after {
            width: 4px;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__hidden-menu {
        max-height: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
        padding-left: 60px;

        &__item {
            font-weight: $font-bold;
            color: $header-menu-inner-item-color;
            margin: 25px 25px 0 0;
        }
    }
}

.menu-language {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
        font-size: 1.625rem;
        font-weight: $font-bold;
        color: $header-menu-icon-colors;
        margin: 0 10px;
        padding: 5px 8px;

        &.active {
            color: $main-theme-color;
        }
    }
}

.welcome {
    position: relative;
    padding: 80px 0;

    .section-shape {
        &--left {
            top: 175px;
            left: 0;
            width: 270px;
            height: 345px;
        }

        &--right {
            top: 0;
            right: 40px;
            width: 540px;
            height: 340px;
        }   
    }

    &__logo {
        margin-left: 10rem;
        width: 150px;

        svg {
            fill: #185478;
        }
    }

    &__title, &__holder {
        position: relative;
        z-index: 2;
    }

    &__title {
        color: #185478;
        font-weight: $font-extra-bold;
        padding: 0 2.5rem;
        font-size: 3.5rem;

        span {
            display: block;
            padding-left: 9.375rem;
            color: $main-theme-color;
            font-size: 1.875rem;
        }
    }

    &__description {
        padding-left: 10.3125rem;
        font-size: $paragraph-font-size;
        line-height: $paragraph-line-height;
        color: $thernary-text-color;
        margin-top: 4.375rem;

        a {
            color: #1b3f55;
            font-weight: $font-bold;
            text-decoration: underline;
        }
    }
}

.services {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 2.5rem 5rem 3.75rem 5rem;
    z-index: 2;

    &--gallery {
        .services__title {
            transition: none;
        }
    }

    &--no-bottom-padding {
        padding-bottom: 0;
    }

    &__item {
        position: relative;
        width: calc(33.3% - 10px);
        margin: 5px;
        background-color: $main-theme-color;
        padding-bottom: calc(33.3% - 40px);
        overflow: hidden;

        &--viewall {
            position: relative;
            padding: 20px;
            background-color: #bfc7cc;
        }

        &--no-image {
            .services__front {
                background-color: $main-theme-color;

                &:before {
                    display: none;
                }
            }

            .services__category {
                display: none;
            }

            &:nth-child(2n+1) .services__front {
                background-color: #185478;
            }
        }
    }
        
    &__head-title {
        position: absolute;
        top: -5px;
        padding: 0 25px;
        font-size: 5.75rem;
        font-weight: $font-bold;
        line-height: 0.72;
        letter-spacing: -7px;
        color:$secondary-text-color;
        text-transform: uppercase;
        word-break: break-word;
        opacity: 0;

        &.animated {
            opacity: 1;
        }
    }

    &__front {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0, 0.15);
            z-index: 1;
        }
    }

    &__title {
        position: absolute;
        width: 90%;
        color: $secondary-text-color;
        font-size: 1.6875rem;
        font-weight: $font-bold;
        text-transform: uppercase;
        text-align: center;
        padding: 10px 20px;
        opacity: 0;
        z-index: 2;

        &.animated {
            opacity: 1;
        }
    }

    &__back {
        position: absolute;
        width: 100%;
        max-width: 400px;
        left: 50%;
        transform: translateX(-50%);
        padding: 55px 75px 55px 55px;
    }

    &__back-title {
        font-size: 1.375rem;
        font-weight: $font-bold;
        color: #182730;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    &__description {
        font-size: 0.9375rem;
        line-height: 1.375;
        color: $secondary-text-color;
        margin-bottom: 25px;
    }

    &__link {
        font-size: 13px;
        font-weight: $font-bold;
        color: #182730;
        cursor: pointer;
    }

    &__all {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 1.375rem;
        font-weight: $font-bold;
        letter-spacing: 3px;

        span {
            color: #00c7d2;
        }
    }

    &__category {
        position: absolute;
        font-weight: $font-bold;
        color: $secondary-text-color;
        writing-mode: tb-rl;
        top: 7px;
        right: 15px;
        min-height: 160px;
        transform: rotate(-180deg);
    }

    &__shape {
        border-top: 15px solid $secondary-text-color;
        border-left: 15px solid transparent;
        height: 0;
        width: 30px;
        margin-top: 10px;
        display: inline-block;
        transform: rotate(90deg)
    }
}

.banner-wrapper {
    position: relative;
    z-index: 3;

    &__shape {
        position: absolute;
        left: 55px;
        top: 80px;
        width: 320px;
        height: 410px;
        z-index: 3;
        fill: #fff;

        &--bottom {
            left: 540px;
            top: auto;
            bottom: 30px;
            width: 120px;
            height: 85px;
            z-index: 4;
        }

        &--mobile {
            display: none;
        }
    }
}

.banner {
    padding: 50px 0;

    &--inner-page {
        padding: 0;

        .banner__text {
            bottom: 75px;
        }
    }

    &__shape {
        position: absolute;
        top: -2px;
        left: 85px;
        width: 846px;
        height: 475px;
        fill: #fff;

        &--mobile {
            display: none;
        }
    }

    &__image {
        z-index: 1;
    }

    &__text {
        position: absolute;
        left: 80px;
        bottom: -5px;
        width: 100%;
        font-size: 5.625rem;
        font-weight: 700;
        line-height: 0.77;
        text-transform: uppercase;
        color: $secondary-text-color;
        letter-spacing: -6px;
        z-index: 1;

        span {
            display: block;
            color: $main-theme-color;
        }
    }

    &__description {
        position: absolute;
        left: 90px;
        bottom: 140px;
        width: 100%;
        max-width: 700px;
        padding: 0 140px;
        background-color: rgba(232,238,241, 0.8);
        z-index: 3;
        line-height: 2;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 210px;
            left: 0;
            background-image: url('data:image/svg+xml,%3Csvg%20id%3D%22Layer_1%22%20data-name%3D%22Layer%201%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20314.64%2094.42%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23e8eef1%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ctitle%3Etriangle_top%3C%2Ftitle%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%22157.18%200%2078.58%2047.38%200%2094.42%20314.64%2094.42%20236.07%2047.38%20157.18%200%22%2F%3E%3C%2Fsvg%3E');
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.8;
        }

        &:before {
           bottom: 100%;
        }

        &:after {
            top: 100%;
            transform: rotate(180deg);
        }

        a {
            text-decoration: underline;
            color: #041119;
            font-weight: $font-bold;
        }
    }
}

.main-paralax {
    position: relative;
    height: 700px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    overflow: hidden;
    padding: 40px 70px;

    @supports (-webkit-overflow-scrolling: touch) {
      background-attachment: scroll;
    }

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0, 0.2);
    }

    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

.breadcrumbs {
    position: relative;
    overflow: hidden;
    padding: 15px;
    background-color: #a9b7c0;

    &--akcent {
        background-color: $main-theme-color;  
    }

    &--type2 {
        background-color: #185478;
    }

    &--no-space .breadcrumbs__item:first-child {
        margin-left: 0;
    }

    &:after {
        position: absolute;
        top: 0;
        right: -25px;
        content: '';
        width: 315px;
        height: 100%;
        transform: skew(45deg);
        background: #fff;
        z-index: 1;
    }

    &__item {
        position: relative;
        font-family: $font-montserrat;
        font-size: 0.875rem;
        color: $secondary-text-color;
        z-index: 2;

        &:first-child {
            margin-left: 70px;
        }

        &:last-child:after {
            display: none;
        }

        &:after {
            content: ' »';
        }
    }
}

.blog {
    position: relative;
    padding: 3.75rem 2.5rem 5.3125rem 2.5rem;
    z-index: 4;

    &__shape {
        position: absolute;
        right: 5.625rem;
        width: 320px;
        height: 340px;
        top: -130px;
        z-index: 2;
    }

    &__title {
        position: relative;
        text-align: right;
        text-transform: uppercase;
        padding-right: 16.5rem;
        font-size: 80px;
        font-weight: $font-bold;
        letter-spacing: -2.5px;
        line-height: 0.9;
        color: #97a6ae;
        margin-left: auto;
        z-index: 3;

        span {
            color: $main-theme-color;
            display: block;
        }
    }

    &__holder {
        margin-top: 60px;
    }

    &__item {
        padding: 0 45px;

        &--akcent {
            .blog__item-title {
                color: #109aa2;
            }

            .date-component__link {
                color: #60bcc1;
            }
        }
    }

    &__category {
        font-size: 1.125rem;
        color: #97a6ae;
        text-align: right;
        padding-right: 40px;
    }

    &__item-title {
        font-size: 1.5rem;
        line-height: 1.41;
        font-weight: $font-bold;
        color: #384750;
        margin-top: 30px;
    }

    &__description {
        margin-top: 25px;
        line-height: 1.6875;
        color: #041119;
    }

    &__bottom {
        margin-top: 40px;
    }
}


.date-component {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__date {
        position: relative;
        font-size: 6.5rem;
        font-weight: $font-bold;
        line-height: 1;
        color: #d8dfe3;

        span {
            position: absolute;
            top: 25px;
            right: -10px;
            font-size: 1.375rem;
            color: #384750;
        }
    }

    &__link {
        font-size: 0.9375rem;
        font-weight: $font-bold;
        color: #78a0b8;
        letter-spacing: 3px;
    }
}

.promotions {
    padding: 3.125rem 5.625rem;

    &--inner-page {
        padding-bottom: 0;
    }

    &__item {
        display: flex;
        margin-bottom: 10px;

        &--type2 .promotions, &--type4 .promotions {
            
            &__image {
                order: 2;
                margin-right: 0;
            }

            &__info {
                margin-right: 10px;
                margin-left: 0;
                padding-left: 108px;
                background-color: #114361;
            }

            &__title, &__link {
                color: #64f7ff
            }

            &__category {
                text-align: left;
            }

            &__shape {
                right: auto;
                left: -42px;
                height: 110px;
            }
        }

        &--type3 .promotions {

            &__info {
                background-color: #b1bdc4;
            }

            &__title, &__link {
                color: #1b3f55
            }

            &__shape {
                height: 120px;
            }
        }

        &--type4 .promotions {

            &__shape {
                left: -45px;
                width: 140px;
            }
        }
    }

    &__image {
        width: calc(50% - 5px);
        margin-right: 5px;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__info {
        position: relative;
        width: calc(50% - 5px);
        margin-left: 5px;
        background-color: $main-theme-color;
        padding: 55px 6rem;
        font-weight: $font-bold;
    }

    &__category, &__title, &__description {
        opacity: 0;
        animation-delay: 400ms;

        &.animated {
            opacity: 1;
        }
    }

    &__category {
        text-align: right;
        font-size: 1.375rem;
        color: $secondary-text-color;
        margin-bottom: 3rem;
    }

    &__title {
        color: #1b3f55;
        font-size: 1.875rem;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    &__description {
        color: $secondary-text-color;
        font-size: 1.125rem;
        font-weight: $font-regular;
        margin-bottom: 50px;
        line-height: 1.5;
    }

    &__link {
        color: #1b3f55;
        letter-spacing: 3px;
    }

    &__shape {
        position: absolute;
        width: 125px;
        height: 105px;
        top: 15px;
        right: -50px;
        fill: #fff;
    }
}

.info-section {
    position: relative;
    padding: 140px 0 90px 0;

    &--type2 {
        padding-bottom: 140px;
    }

    &--type3 {
        padding-top: 70px;
    }

    &--akcent .info-section__description {
        color: $thernary-text-color;
    }

    &__wrapper {
        position: relative;
    }

    &__title {
        font-size: 4.375rem;
        font-weight: $font-extra-bold;
        color: #185478;
        margin-bottom: 35px;
        padding-left: 4.375rem;

        &--akcent {
            color: $main-theme-color;
        }

        &--type2 {
            color: #97a6ae;
        }
    }
    
    &__description {
        position: relative;
        color: #404548;
        padding-left: 10.3125rem;
        line-height: 1.75;
        z-index: 2;

        a {
            font-weight: $font-bold;
            text-decoration: underline;
        }

        &--two-columns {
            display: flex;
        }
    }

    &__shape {
        position: absolute;
        left: 0;
        bottom: -200px;
        width: 370px;
        height: 400px;
        z-index: 1;

        &--center {
            top: 150px;
            bottom: auto;
            height: 300px;
            width: 175px;
        }

        &--inner {
            width: 230px;
            height: 200px;
            bottom: auto;
            margin-top: -70px;
        }

        &--center-text {
            width: 170px;
            height: 290px;
            margin-top: 100px;
            bottom: auto;
        }

        &--type5 {
            width: 140px;
            height: 115px;
            bottom: auto;
            top: 10px;
        }

        &--up {
            margin-top: 50px;
        }
    }

    &__back {
        position: absolute;
        right: 90px;
        top: 45px;
        font-weight: $font-bold;
        color: #a9b7c0;
        display: flex;
        align-items: flex-end;

        span:first-child {
            padding-bottom: 5px;
        }

        &-figure {
            display: inline-flex;
            flex-wrap: wrap;
            width: 60px;
            margin-left: 10px;

            i {
                width: 25px;
                height: 25px;
                border: 3px solid #ebeff4;
                margin: 2px;

                &:nth-of-type(3) {
                    border-color: $main-theme-color;
                }
            }
        }
    }

    &__filter {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 90px 0 60px 0;

        &-item {
            font-size: 1.875rem;
            font-weight: $font-bold;
            margin: 10px 20px;
            color: #c6ced2;

            &.active {
                color: $main-theme-color;
            }
        }
    }

    &__col {
        width: 50%;
        padding-right: 75px;
        color: #404548;

        &:last-child {
            padding-left: 75px;
            border-left: 4px solid #ebeff4;
        }

        article + article {
            margin-top: 40px;
        }
    }
}

.products {
    padding: 20px 80px;
    display: flex;
    flex-wrap: wrap;

    &--type2 {
        padding: 55px 65px;

        .products {

            &__item {
                padding: 0 35px;
                margin-bottom: 100px;

                &--akcent {
                    
                    .products__title {
                        color: #109aa2;
                    }

                    .date-component__link {
                        color: #60bcc1;
                    }
                }
            }

            &__title, &__description {
                padding-left: 0;
            }

            &__title {
                margin-bottom: 20px;
            }

            &__description {
                margin-bottom: 25px;
                line-height: 1.6875;
            }

            &__bottom {
                padding-right: 60px;
            }
        }
    }

    &--group .products__item {
        margin-bottom: 10px;
    }

    &--gray {
        background-color: #f7f7f7;
    }

    &__item {
        width: 33.33%;
        padding: 0 5px;

        &:nth-of-type(2n+2) .products__category {
            color: #175378;
        }
    }

    &__category {
        padding-left: 30px;
        font-size: 1.25rem;
        font-weight: $font-bold;
        color: $main-theme-color;
        text-transform: uppercase;
    }

    &__image {
        margin-bottom: 25px;
        margin-top: 15px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }

    &__title, &__description {
        padding: 0 25px;
    }

    &__title {
        font-size: 1.5rem;
        color: #384750;
        font-weight: $font-bold;
        margin-bottom: 10px;
    }

    &__description {
        line-height: 1.5;
        color: #404548;
        margin-bottom: 7.5rem;
        padding-right: 2.8125rem;
    }
}

.btn {
    padding: 20px 90px;
    font-family: $font-montserrat;
    font-size: 1.25rem;
    font-weight: $font-bold;
    text-transform: uppercase;
    display: inline-block;
    border: 3px solid $main-theme-color;
    background: none;
    cursor: pointer;
    outline: none;
    color: $main-theme-color;
    min-width: 170px;
    border-radius: 0;
    -webkit-appearance: none;
}

.form {

    &__row {
        margin-top: 35px;
        font-family: $font-montserrat;
        font-size: 0.9375rem;

        &--two-columns {
            display: flex;
            justify-content: space-between;
        }

        &--center {
            align-items: center;
        }

        &--mt-40 {
            margin-top: 2.5rem;
        }

        &--no-margin {
            margin: 0;
        }

        &--buttons {
            padding: 0 15px;
        }
    }

    &__col {
        padding: 0 15px;
        display: flex;
        align-items: flex-end;

        &-50{ 
            width: 50%;
        }

        &--textarea {
            display: block;

            .form__label {
                margin-bottom: 10px;
            }
        }

        &--no-padding {
            padding: 0;
        }
    }

    &__label {
        font-weight: $font-bold;
        color: $main-theme-color;
        white-space: nowrap;
        margin-right: 10px;
        display: inline-block;
    }

    &__input, &__textarea {
        display: block;
        width: 100%;
        border-radius: 0;
        color: #404548;
        font-weight: $font-bold;

        &::placeholder {
            color: #404548;
        }
    }

    &__input {
        padding: 10px 15px;
        font-size: 1.25rem;
        border: none;
        border-bottom: 2px solid #ebeff4;
    }

    &__textarea {
        padding: 30px;
        min-height: 240px;
        font-weight: $font-regular;
        resize: vertical;
        border: 2px solid #ebeff4;
    }

    &__checkbox {
        display: none;

        & + .form__label {
            position: relative;
            padding-left: 45px;
            cursor: pointer;
            display: flex;
            align-items: center;
            min-height: 30px;
            margin-left: 55px;
            
            &:before {
                position: absolute;
                content: '';
                left: 0;
                width: 25px;
                height: 25px;
                border: 2px solid #d0d4d8;
            }

            &:after {
                position: absolute;
                content: '\2714';
                left: 8px;
                top: 7px;
                display: none;
            }
        }   

        &:checked + .form__label:after {
            display: block;
        }
    }
}

.contacts {
    padding: 100px 0;

    &__wrapper {
        padding: 0 70px;
    }

    &__title {
        font-size: 2.5rem;
        font-weight: $font-extra-bold;
        color: $main-theme-color;
        padding: 0 15px;
        margin-bottom: 60px;
    }
}

.gallery-block {
    padding: 100px 0;
}

.inner-info {

    &__nav {
        margin: 90px 0 40px 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__nav-item {

        
        & + & {
            margin-left: 55px; 
        }

        svg {
            width: 75px;
            height: 40px;
            fill: #e0e2e4;
        }

        span {
            display: block;
            font-size: 0.8125rem;
            color: #a9b7c0;
            text-align: center;
            opacity: 0;
        }

        &--back svg {
            width: 55px;
        }
        
    }

    &__content {
        color: #404548;
        font-size: 1rem;
        line-height: 1.75;

        a {
            text-decoration: underline;
            font-weight: $font-bold;
        }

        h1,h2,h3,h4,h5,h6 {
            color: $main-theme-color;
            font-weight: $font-bold;
            margin-bottom: 20px;
            font-size: 1.875rem;
        }

        h1 {
            font-weight: $font-extra-bold;
            font-size: 2.375rem;
            margin-bottom: 50px;
        }

        div {
            margin-bottom: 35px;
        }

        .inserted-file {
            padding: 10px 0;

            .fa {
                margin-right: 20px;
            }
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
    }

    &__date {
        font-size: 3.75rem;
        font-weight: $font-bold;
        line-height: 1;
        color: #d8dfe3;
        display: flex;
        align-items: flex-start;
        padding-right: 40px;
        margin-top: 50px;

        span {
            margin: 13px 0 0 10px;
            font-size: 20px;
            color: #6d7a82; 
        }

        & + & {
            border-left: 2px solid #edeff0;
            padding-left: 40px;
            padding-right: 0;;
        }
    }
}

.product-details {

    &__top, &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
    }

    &__bottom {
        margin-bottom: 100px;
    }

    &__brand {

        img {
            max-width: 150px;
        }
    }

    &__image {
        float: left;
        margin: 0 60px 50px 0;
    }

    &__gallery {
        font-size: 1.25rem;
        font-weight: $font-bold;
        color: #96a6ad;
        text-align: center;
        text-transform: uppercase;
        margin-top: 10px;

        a {
            border: 3px solid #f4f6f9;
            display: block;
            padding: 10px;
        }
    }

    &__category {
        font-size: 1.625rem;
        font-weight: $font-bold;
        text-transform: uppercase;
        color: $main-theme-color;
        margin-bottom: 55px;
        display: inline-block;
        text-decoration: none !important;
    }

    &__title {
        font-size: 2.375rem;
        font-weight: $font-extra-bold;
        color: #185478;
        margin-bottom: 40px;
    }

    &__description {
        color: #404548;
        line-height: 1.75;

        > div {
            margin-bottom: 35px;
        }
    }

    &__text {

        a {
            text-decoration: underline;
            font-weight: $font-bold;
        }

        p {
            margin-bottom: 10px;
        }

        h2,h3,h4,h5,h6 {
            color: #185478;
            font-weight: $font-bold;
            margin-bottom: 15px;
            font-size: 1.875rem;
        }

        .inserted-file {
            padding: 10px 0;

            .fa {
                margin-right: 20px;
            }
        }
    } 
}

.footer {
    position: relative;
    display: flex;
    font-weight: $font-bold;
    
    &__left {
        width: 35.5%;
        padding: 0 2.5rem 0 5.3125rem;
        background-color:$footer-left-background-color;
    }

    &__right { 
        width: 64.5%;
        padding: 4.375rem 2.5rem 3.125rem 5rem;
        background-color:$footer-right-background-color;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    
    &__gotop {
        position: absolute;
        bottom: 30px;
        right: 45px;
        color: $footer-text-hover-color;
        cursor: pointer;
    }
    
    &__title {
        margin-top: -4px;
        font-size: 4.375rem;
        line-height: 0.8;
        color: $secondary-text-color;
        letter-spacing: -6px;
        text-transform: uppercase;
    }

    &__info {
        padding: 60px 0;
        font-weight: $font-regular;
        font-size: 1.125rem;
        line-height: 1.875;
        text-align: left;
        color: $secondary-text-color;
    }

    &__navigation {
        display: flex;
        justify-content: space-between;
        width: 60%;

        &-group {
            display: flex;
            flex-direction: column;
            padding-right: 60px;
        }

        &-item {
            margin: 10px 5px;
            text-transform: uppercase;
            font-size: 1.25rem;
            color: $footer-text-color;
            display: inline-block;

            &.active {
			    color: $footer-text-hover-color;
            }
        }
    }

    &__social {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &-item {
            width: 40px;
            height: 40px;
            margin: 0 25px;

            &:first-child {
                margin: 0 15px;
            }

            svg {
                fill: $footer-social-color;
            }
        }
    }
   
    &__allrights {
        min-width: 100%;
        margin-top: 4.6875rem;
        font-size: 0.875rem;
        font-weight: $font-regular;
        color: $footer-bottom-text-color;

        a {
            color: #c7dbe7;
            font-weight: $font-bold;
        }
    }

    &__shape {
        width: 130px;
        height: 50px;
        bottom: 0;
        right: 120px;
    }
}