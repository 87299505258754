@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  transition: all .2s ease;
  box-sizing: border-box; }

html.noScroll, body.noScroll {
  position: relative;
  overflow: hidden; }

html {
  height: 100%;
  font-size: 16px; }

body {
  color: #041119;
  line-height: 1.3;
  font-family: "Montserrat", sans-serif; }
  body.noScroll .header__inner {
    padding-right: 79px; }
  body.noScroll .header.fixed .header__inner {
    padding-right: 40px; }

a {
  color: inherit;
  text-decoration: none; }

img {
  max-width: 100%; }

svg {
  max-width: 100%;
  max-height: 100%; }

@keyframes scrollDownPoint {
  0% {
    transform: translate(0, 0);
    opacity: 0; }
  40% {
    opacity: 1; }
  80% {
    transform: translate(0, 20px);
    opacity: 0; }
  100% {
    opacity: 0; } }

.wrapper {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px; }

#sliderMain {
  min-height: 1080px; }
  #sliderMain * {
    transition: none; }

.section-shape {
  position: absolute; }
  .section-shape svg {
    width: 100%;
    height: 100%; }

.hamburger-icon {
  width: 60px;
  height: 45px;
  position: relative;
  transform: rotate(0deg);
  transition: none;
  transform: scale(0.65);
  cursor: pointer; }
  .hamburger-icon i {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: #fff;
    border-radius: 0;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out; }
    .hamburger-icon i:nth-child(1) {
      top: 0px;
      transform-origin: left center;
      background: #00c7d2; }
    .hamburger-icon i:nth-child(2) {
      top: 18px;
      transform-origin: left center; }
    .hamburger-icon i:nth-child(3) {
      top: 36px;
      transform-origin: left center; }
  .hamburger-icon.active i:nth-child(1) {
    transform: rotate(45deg);
    top: -3px;
    left: 8px; }
  .hamburger-icon.active i:nth-child(2) {
    width: 0%;
    opacity: 0; }
  .hamburger-icon.active i:nth-child(3) {
    transform: rotate(-45deg);
    top: 39px;
    left: 8px;
    background: #00c7d2; }

.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px; }
  .slick-dots > li {
    margin: 0 15px;
    background: url("../images/blog-slider-bullet.svg") no-repeat center;
    border: none;
    width: 30px;
    height: 30px;
    font-size: 0;
    cursor: pointer; }
    .slick-dots > li.slick-active {
      background-image: url("../images/slider-bullet-active.svg"); }
    .slick-dots > li > button {
      display: none; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20; }
  .header.fixed {
    background-color: #0f3e5a; }
    .header.fixed .header__logo {
      width: 140px;
      height: 35px; }
    .header.fixed .header__inner {
      padding: 10px 25px 10px; }
    .header.fixed .header__button {
      flex-direction: row; }
      .header.fixed .header__button .hamburger-icon {
        order: 2;
        transform: scale(0.55); }
      .header.fixed .header__button span {
        margin-left: 0; }
  .header.active .header__button {
    background: #f0f3f4; }
    .header.active .header__button i {
      background: #185478; }
    .header.active .header__button span {
      opacity: 0; }
  .header.fixed.active .header__menu-top {
    padding-top: 10px; }
  .header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 65px 10px;
    transition: none; }
  .header__logo {
    width: 360px;
    height: 105px; }
    .header__logo__icon {
      width: 100%;
      fill: #fff; }
  .header__button {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 21; }
    .header__button span {
      text-transform: uppercase;
      font-size: 0.75rem;
      color: #fff; }
  .header__menu {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    background: linear-gradient(to right, white 0%, white 64%, #f0f3f4 64%, #f0f3f4 100%);
    visibility: hidden;
    overflow-y: auto;
    opacity: 0;
    z-index: 20; }
    .header__menu.active {
      opacity: 1;
      visibility: visible; }
      .header__menu.active .main-nav__item {
        opacity: 1; }
    .header__menu-top {
      display: flex;
      align-items: center;
      padding: 55px 50px 40px 75px; }
    .header__menu-nav {
      display: flex;
      justify-content: center; }
    .header__menu-logo {
      width: 55px;
      height: 55px;
      margin-right: 75px; }
      .header__menu-logo-icon {
        fill: #185478; }

.rev_slider_wrapper {
  position: relative;
  z-index: 10; }
  .rev_slider_wrapper .slotholder {
    position: relative; }
    .rev_slider_wrapper .slotholder:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0) 60%); }
  .rev_slider_wrapper .section-shape {
    z-index: 21;
    opacity: 0;
    transition: opacity 1s ease !important; }
    .rev_slider_wrapper .section-shape.visible {
      opacity: 1; }
    .rev_slider_wrapper .section-shape--left {
      top: 0;
      left: 0;
      width: 800px;
      height: 430px; }
    .rev_slider_wrapper .section-shape--right {
      bottom: 0;
      right: 0;
      width: 315px;
      height: 240px; }
  .rev_slider_wrapper .main_caption_name {
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-size: 4.375rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.1;
    color: #fff; }
    .rev_slider_wrapper .main_caption_name--subtitle {
      display: block;
      font-size: 2.1875rem;
      margin-top: 20px;
      text-transform: initial;
      font-weight: 400; }
  .rev_slider_wrapper__text-layer {
    color: #fff !important;
    text-transform: uppercase; }
  .rev_slider_wrapper__scroll-down {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    width: 1.875rem;
    height: 3.125rem;
    border: 2px solid #fff;
    border-radius: 3.125rem;
    margin: auto;
    cursor: pointer;
    z-index: 20; }
    .rev_slider_wrapper__scroll-down:before {
      position: absolute;
      top: 10px;
      left: 50%;
      content: '';
      width: 6px;
      height: 6px;
      margin-left: -3px;
      background-color: #fff;
      border-radius: 100%;
      animation: scrollDownPoint 2s infinite; }
  .rev_slider_wrapper .tp-caption {
    white-space: inherit !important; }
  .rev_slider_wrapper .tp-bullets {
    left: calc(100% - 30px) !important; }
  .rev_slider_wrapper .tp-bullet {
    border-radius: 0;
    background: url("../images/slider-bullet.svg") no-repeat center;
    box-shadow: none;
    width: 25px;
    height: 25px; }
    .rev_slider_wrapper .tp-bullet:after {
      display: none; }
    .rev_slider_wrapper .tp-bullet.selected {
      background: url("../images/slider-bullet-active.svg") no-repeat center; }
  .rev_slider_wrapper--innerpage .section-shape {
    opacity: 1; }
  .rev_slider_wrapper--innerpage .slotholder {
    height: 915px;
    background-size: cover; }
  .rev_slider_wrapper--small .slotholder {
    height: 605px; }

.main-nav {
  position: relative;
  display: flex;
  flex-flow: column;
  padding: 0 50px 0 75px;
  width: 64.5%;
  z-index: 22; }
  .main-nav--secondary {
    width: 35.5%; }
    .main-nav--secondary .main-nav__label {
      font-size: 1.625rem; }
    .main-nav--secondary .main-nav__expand {
      width: 20px;
      height: 20px; }
  .main-nav__item {
    font-family: "Montserrat", sans-serif;
    margin-top: 2.5rem;
    opacity: 0;
    transition: 0.3s opacity 200ms; }
    .main-nav__item input {
      display: none; }
      .main-nav__item input:checked + span {
        color: #00aeb8; }
        .main-nav__item input:checked + span i:before {
          background-color: #244356; }
        .main-nav__item input:checked + span i:after {
          opacity: 0; }
      .main-nav__item input:checked ~ div {
        max-height: 600px;
        transition: max-height 1.2s ease-in-out; }
  .main-nav__label {
    color: #5e5e5e;
    text-transform: uppercase;
    text-align: left;
    font-size: 1.625rem;
    font-weight: 700;
    color: #768893;
    display: inline-flex;
    align-items: center;
    transition: none; }
  .main-nav__expand {
    position: relative;
    display: inline-block;
    min-width: 25px;
    height: 25px;
    cursor: pointer;
    margin-left: 10px; }
    .main-nav__expand:before, .main-nav__expand:after {
      position: absolute;
      content: '';
      background-color: #00c7d2;
      transition: opacity .3s ease; }
    .main-nav__expand:before {
      width: 100%;
      height: 4px;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
    .main-nav__expand:after {
      width: 4px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%); }
  .main-nav__hidden-menu {
    max-height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
    padding-left: 60px; }
    .main-nav__hidden-menu__item {
      font-weight: 700;
      color: #185478;
      margin: 25px 25px 0 0; }

.menu-language {
  display: flex;
  align-items: center;
  justify-content: center; }
  .menu-language__item {
    font-size: 1.625rem;
    font-weight: 700;
    color: #185478;
    margin: 0 10px;
    padding: 5px 8px; }
    .menu-language__item.active {
      color: #00c7d2; }

.welcome {
  position: relative;
  padding: 80px 0; }
  .welcome .section-shape--left {
    top: 175px;
    left: 0;
    width: 270px;
    height: 345px; }
  .welcome .section-shape--right {
    top: 0;
    right: 40px;
    width: 540px;
    height: 340px; }
  .welcome__logo {
    margin-left: 10rem;
    width: 150px; }
    .welcome__logo svg {
      fill: #185478; }
  .welcome__title, .welcome__holder {
    position: relative;
    z-index: 2; }
  .welcome__title {
    color: #185478;
    font-weight: 800;
    padding: 0 2.5rem;
    font-size: 3.5rem; }
    .welcome__title span {
      display: block;
      padding-left: 9.375rem;
      color: #00c7d2;
      font-size: 1.875rem; }
  .welcome__description {
    padding-left: 10.3125rem;
    font-size: 1rem;
    line-height: 1.75;
    color: #1b3f55;
    margin-top: 4.375rem; }
    .welcome__description a {
      color: #1b3f55;
      font-weight: 700;
      text-decoration: underline; }

.services {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 2.5rem 5rem 3.75rem 5rem;
  z-index: 2; }
  .services--gallery .services__title {
    transition: none; }
  .services--no-bottom-padding {
    padding-bottom: 0; }
  .services__item {
    position: relative;
    width: calc(33.3% - 10px);
    margin: 5px;
    background-color: #00c7d2;
    padding-bottom: calc(33.3% - 40px);
    overflow: hidden; }
    .services__item--viewall {
      position: relative;
      padding: 20px;
      background-color: #bfc7cc; }
    .services__item--no-image .services__front {
      background-color: #00c7d2; }
      .services__item--no-image .services__front:before {
        display: none; }
    .services__item--no-image .services__category {
      display: none; }
    .services__item--no-image:nth-child(2n+1) .services__front {
      background-color: #185478; }
  .services__head-title {
    position: absolute;
    top: -5px;
    padding: 0 25px;
    font-size: 5.75rem;
    font-weight: 700;
    line-height: 0.72;
    letter-spacing: -7px;
    color: #fff;
    text-transform: uppercase;
    word-break: break-word;
    opacity: 0; }
    .services__head-title.animated {
      opacity: 1; }
  .services__front {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center; }
    .services__front:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.15);
      z-index: 1; }
  .services__title {
    position: absolute;
    width: 90%;
    color: #fff;
    font-size: 1.6875rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    padding: 10px 20px;
    opacity: 0;
    z-index: 2; }
    .services__title.animated {
      opacity: 1; }
  .services__back {
    position: absolute;
    width: 100%;
    max-width: 400px;
    left: 50%;
    transform: translateX(-50%);
    padding: 55px 75px 55px 55px; }
  .services__back-title {
    font-size: 1.375rem;
    font-weight: 700;
    color: #182730;
    text-transform: uppercase;
    margin-bottom: 10px; }
  .services__description {
    font-size: 0.9375rem;
    line-height: 1.375;
    color: #fff;
    margin-bottom: 25px; }
  .services__link {
    font-size: 13px;
    font-weight: 700;
    color: #182730;
    cursor: pointer; }
  .services__all {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 1.375rem;
    font-weight: 700;
    letter-spacing: 3px; }
    .services__all span {
      color: #00c7d2; }
  .services__category {
    position: absolute;
    font-weight: 700;
    color: #fff;
    writing-mode: tb-rl;
    top: 7px;
    right: 15px;
    min-height: 160px;
    transform: rotate(-180deg); }
  .services__shape {
    border-top: 15px solid #fff;
    border-left: 15px solid transparent;
    height: 0;
    width: 30px;
    margin-top: 10px;
    display: inline-block;
    transform: rotate(90deg); }

.banner-wrapper {
  position: relative;
  z-index: 3; }
  .banner-wrapper__shape {
    position: absolute;
    left: 55px;
    top: 80px;
    width: 320px;
    height: 410px;
    z-index: 3;
    fill: #fff; }
    .banner-wrapper__shape--bottom {
      left: 540px;
      top: auto;
      bottom: 30px;
      width: 120px;
      height: 85px;
      z-index: 4; }
    .banner-wrapper__shape--mobile {
      display: none; }

.banner {
  padding: 50px 0; }
  .banner--inner-page {
    padding: 0; }
    .banner--inner-page .banner__text {
      bottom: 75px; }
  .banner__shape {
    position: absolute;
    top: -2px;
    left: 85px;
    width: 846px;
    height: 475px;
    fill: #fff; }
    .banner__shape--mobile {
      display: none; }
  .banner__image {
    z-index: 1; }
  .banner__text {
    position: absolute;
    left: 80px;
    bottom: -5px;
    width: 100%;
    font-size: 5.625rem;
    font-weight: 700;
    line-height: 0.77;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: -6px;
    z-index: 1; }
    .banner__text span {
      display: block;
      color: #00c7d2; }
  .banner__description {
    position: absolute;
    left: 90px;
    bottom: 140px;
    width: 100%;
    max-width: 700px;
    padding: 0 140px;
    background-color: rgba(232, 238, 241, 0.8);
    z-index: 3;
    line-height: 2; }
    .banner__description:before, .banner__description:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 210px;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg%20id%3D%22Layer_1%22%20data-name%3D%22Layer%201%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20314.64%2094.42%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23e8eef1%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ctitle%3Etriangle_top%3C%2Ftitle%3E%3Cpolygon%20class%3D%22cls-1%22%20points%3D%22157.18%200%2078.58%2047.38%200%2094.42%20314.64%2094.42%20236.07%2047.38%20157.18%200%22%2F%3E%3C%2Fsvg%3E");
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.8; }
    .banner__description:before {
      bottom: 100%; }
    .banner__description:after {
      top: 100%;
      transform: rotate(180deg); }
    .banner__description a {
      text-decoration: underline;
      color: #041119;
      font-weight: 700; }

.main-paralax {
  position: relative;
  height: 700px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-backface-visibility: hidden;
  overflow: hidden;
  padding: 40px 70px; }
  @supports (-webkit-overflow-scrolling: touch) {
    .main-paralax {
      background-attachment: scroll; } }
  .main-paralax:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2); }
  .main-paralax__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.breadcrumbs {
  position: relative;
  overflow: hidden;
  padding: 15px;
  background-color: #a9b7c0; }
  .breadcrumbs--akcent {
    background-color: #00c7d2; }
  .breadcrumbs--type2 {
    background-color: #185478; }
  .breadcrumbs--no-space .breadcrumbs__item:first-child {
    margin-left: 0; }
  .breadcrumbs:after {
    position: absolute;
    top: 0;
    right: -25px;
    content: '';
    width: 315px;
    height: 100%;
    transform: skew(45deg);
    background: #fff;
    z-index: 1; }
  .breadcrumbs__item {
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-size: 0.875rem;
    color: #fff;
    z-index: 2; }
    .breadcrumbs__item:first-child {
      margin-left: 70px; }
    .breadcrumbs__item:last-child:after {
      display: none; }
    .breadcrumbs__item:after {
      content: ' »'; }

.blog {
  position: relative;
  padding: 3.75rem 2.5rem 5.3125rem 2.5rem;
  z-index: 4; }
  .blog__shape {
    position: absolute;
    right: 5.625rem;
    width: 320px;
    height: 340px;
    top: -130px;
    z-index: 2; }
  .blog__title {
    position: relative;
    text-align: right;
    text-transform: uppercase;
    padding-right: 16.5rem;
    font-size: 80px;
    font-weight: 700;
    letter-spacing: -2.5px;
    line-height: 0.9;
    color: #97a6ae;
    margin-left: auto;
    z-index: 3; }
    .blog__title span {
      color: #00c7d2;
      display: block; }
  .blog__holder {
    margin-top: 60px; }
  .blog__item {
    padding: 0 45px; }
    .blog__item--akcent .blog__item-title {
      color: #109aa2; }
    .blog__item--akcent .date-component__link {
      color: #60bcc1; }
  .blog__category {
    font-size: 1.125rem;
    color: #97a6ae;
    text-align: right;
    padding-right: 40px; }
  .blog__item-title {
    font-size: 1.5rem;
    line-height: 1.41;
    font-weight: 700;
    color: #384750;
    margin-top: 30px; }
  .blog__description {
    margin-top: 25px;
    line-height: 1.6875;
    color: #041119; }
  .blog__bottom {
    margin-top: 40px; }

.date-component {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .date-component__date {
    position: relative;
    font-size: 6.5rem;
    font-weight: 700;
    line-height: 1;
    color: #d8dfe3; }
    .date-component__date span {
      position: absolute;
      top: 25px;
      right: -10px;
      font-size: 1.375rem;
      color: #384750; }
  .date-component__link {
    font-size: 0.9375rem;
    font-weight: 700;
    color: #78a0b8;
    letter-spacing: 3px; }

.promotions {
  padding: 3.125rem 5.625rem; }
  .promotions--inner-page {
    padding-bottom: 0; }
  .promotions__item {
    display: flex;
    margin-bottom: 10px; }
    .promotions__item--type2 .promotions__image, .promotions__item--type4 .promotions__image {
      order: 2;
      margin-right: 0; }
    .promotions__item--type2 .promotions__info, .promotions__item--type4 .promotions__info {
      margin-right: 10px;
      margin-left: 0;
      padding-left: 108px;
      background-color: #114361; }
    .promotions__item--type2 .promotions__title, .promotions__item--type2 .promotions__link, .promotions__item--type4 .promotions__title, .promotions__item--type4 .promotions__link {
      color: #64f7ff; }
    .promotions__item--type2 .promotions__category, .promotions__item--type4 .promotions__category {
      text-align: left; }
    .promotions__item--type2 .promotions__shape, .promotions__item--type4 .promotions__shape {
      right: auto;
      left: -42px;
      height: 110px; }
    .promotions__item--type3 .promotions__info {
      background-color: #b1bdc4; }
    .promotions__item--type3 .promotions__title, .promotions__item--type3 .promotions__link {
      color: #1b3f55; }
    .promotions__item--type3 .promotions__shape {
      height: 120px; }
    .promotions__item--type4 .promotions__shape {
      left: -45px;
      width: 140px; }
  .promotions__image {
    width: calc(50% - 5px);
    margin-right: 5px;
    overflow: hidden; }
    .promotions__image img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .promotions__info {
    position: relative;
    width: calc(50% - 5px);
    margin-left: 5px;
    background-color: #00c7d2;
    padding: 55px 6rem;
    font-weight: 700; }
  .promotions__category, .promotions__title, .promotions__description {
    opacity: 0;
    animation-delay: 400ms; }
    .promotions__category.animated, .promotions__title.animated, .promotions__description.animated {
      opacity: 1; }
  .promotions__category {
    text-align: right;
    font-size: 1.375rem;
    color: #fff;
    margin-bottom: 3rem; }
  .promotions__title {
    color: #1b3f55;
    font-size: 1.875rem;
    text-transform: uppercase;
    margin-bottom: 20px; }
  .promotions__description {
    color: #fff;
    font-size: 1.125rem;
    font-weight: 400;
    margin-bottom: 50px;
    line-height: 1.5; }
  .promotions__link {
    color: #1b3f55;
    letter-spacing: 3px; }
  .promotions__shape {
    position: absolute;
    width: 125px;
    height: 105px;
    top: 15px;
    right: -50px;
    fill: #fff; }

.info-section {
  position: relative;
  padding: 140px 0 90px 0; }
  .info-section--type2 {
    padding-bottom: 140px; }
  .info-section--type3 {
    padding-top: 70px; }
  .info-section--akcent .info-section__description {
    color: #1b3f55; }
  .info-section__wrapper {
    position: relative; }
  .info-section__title {
    font-size: 4.375rem;
    font-weight: 800;
    color: #185478;
    margin-bottom: 35px;
    padding-left: 4.375rem; }
    .info-section__title--akcent {
      color: #00c7d2; }
    .info-section__title--type2 {
      color: #97a6ae; }
  .info-section__description {
    position: relative;
    color: #404548;
    padding-left: 10.3125rem;
    line-height: 1.75;
    z-index: 2; }
    .info-section__description a {
      font-weight: 700;
      text-decoration: underline; }
    .info-section__description--two-columns {
      display: flex; }
  .info-section__shape {
    position: absolute;
    left: 0;
    bottom: -200px;
    width: 370px;
    height: 400px;
    z-index: 1; }
    .info-section__shape--center {
      top: 150px;
      bottom: auto;
      height: 300px;
      width: 175px; }
    .info-section__shape--inner {
      width: 230px;
      height: 200px;
      bottom: auto;
      margin-top: -70px; }
    .info-section__shape--center-text {
      width: 170px;
      height: 290px;
      margin-top: 100px;
      bottom: auto; }
    .info-section__shape--type5 {
      width: 140px;
      height: 115px;
      bottom: auto;
      top: 10px; }
    .info-section__shape--up {
      margin-top: 50px; }
  .info-section__back {
    position: absolute;
    right: 90px;
    top: 45px;
    font-weight: 700;
    color: #a9b7c0;
    display: flex;
    align-items: flex-end; }
    .info-section__back span:first-child {
      padding-bottom: 5px; }
    .info-section__back-figure {
      display: inline-flex;
      flex-wrap: wrap;
      width: 60px;
      margin-left: 10px; }
      .info-section__back-figure i {
        width: 25px;
        height: 25px;
        border: 3px solid #ebeff4;
        margin: 2px; }
        .info-section__back-figure i:nth-of-type(3) {
          border-color: #00c7d2; }
  .info-section__filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 90px 0 60px 0; }
    .info-section__filter-item {
      font-size: 1.875rem;
      font-weight: 700;
      margin: 10px 20px;
      color: #c6ced2; }
      .info-section__filter-item.active {
        color: #00c7d2; }
  .info-section__col {
    width: 50%;
    padding-right: 75px;
    color: #404548; }
    .info-section__col:last-child {
      padding-left: 75px;
      border-left: 4px solid #ebeff4; }
    .info-section__col article + article {
      margin-top: 40px; }

.products {
  padding: 20px 80px;
  display: flex;
  flex-wrap: wrap; }
  .products--type2 {
    padding: 55px 65px; }
    .products--type2 .products__item {
      padding: 0 35px;
      margin-bottom: 100px; }
      .products--type2 .products__item--akcent .products__title {
        color: #109aa2; }
      .products--type2 .products__item--akcent .date-component__link {
        color: #60bcc1; }
    .products--type2 .products__title, .products--type2 .products__description {
      padding-left: 0; }
    .products--type2 .products__title {
      margin-bottom: 20px; }
    .products--type2 .products__description {
      margin-bottom: 25px;
      line-height: 1.6875; }
    .products--type2 .products__bottom {
      padding-right: 60px; }
  .products--group .products__item {
    margin-bottom: 10px; }
  .products--gray {
    background-color: #f7f7f7; }
  .products__item {
    width: 33.33%;
    padding: 0 5px; }
    .products__item:nth-of-type(2n+2) .products__category {
      color: #175378; }
  .products__category {
    padding-left: 30px;
    font-size: 1.25rem;
    font-weight: 700;
    color: #00c7d2;
    text-transform: uppercase; }
  .products__image {
    margin-bottom: 25px;
    margin-top: 15px;
    overflow: hidden; }
    .products__image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block; }
  .products__title, .products__description {
    padding: 0 25px; }
  .products__title {
    font-size: 1.5rem;
    color: #384750;
    font-weight: 700;
    margin-bottom: 10px; }
  .products__description {
    line-height: 1.5;
    color: #404548;
    margin-bottom: 7.5rem;
    padding-right: 2.8125rem; }

.btn {
  padding: 20px 90px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  border: 3px solid #00c7d2;
  background: none;
  cursor: pointer;
  outline: none;
  color: #00c7d2;
  min-width: 170px;
  border-radius: 0;
  -webkit-appearance: none; }

.form__row {
  margin-top: 35px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9375rem; }
  .form__row--two-columns {
    display: flex;
    justify-content: space-between; }
  .form__row--center {
    align-items: center; }
  .form__row--mt-40 {
    margin-top: 2.5rem; }
  .form__row--no-margin {
    margin: 0; }
  .form__row--buttons {
    padding: 0 15px; }

.form__col {
  padding: 0 15px;
  display: flex;
  align-items: flex-end; }
  .form__col-50 {
    width: 50%; }
  .form__col--textarea {
    display: block; }
    .form__col--textarea .form__label {
      margin-bottom: 10px; }
  .form__col--no-padding {
    padding: 0; }

.form__label {
  font-weight: 700;
  color: #00c7d2;
  white-space: nowrap;
  margin-right: 10px;
  display: inline-block; }

.form__input, .form__textarea {
  display: block;
  width: 100%;
  border-radius: 0;
  color: #404548;
  font-weight: 700; }
  .form__input::placeholder, .form__textarea::placeholder {
    color: #404548; }

.form__input {
  padding: 10px 15px;
  font-size: 1.25rem;
  border: none;
  border-bottom: 2px solid #ebeff4; }

.form__textarea {
  padding: 30px;
  min-height: 240px;
  font-weight: 400;
  resize: vertical;
  border: 2px solid #ebeff4; }

.form__checkbox {
  display: none; }
  .form__checkbox + .form__label {
    position: relative;
    padding-left: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 30px;
    margin-left: 55px; }
    .form__checkbox + .form__label:before {
      position: absolute;
      content: '';
      left: 0;
      width: 25px;
      height: 25px;
      border: 2px solid #d0d4d8; }
    .form__checkbox + .form__label:after {
      position: absolute;
      content: '\2714';
      left: 8px;
      top: 7px;
      display: none; }
  .form__checkbox:checked + .form__label:after {
    display: block; }

.contacts {
  padding: 100px 0; }
  .contacts__wrapper {
    padding: 0 70px; }
  .contacts__title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #00c7d2;
    padding: 0 15px;
    margin-bottom: 60px; }

.gallery-block {
  padding: 100px 0; }

.inner-info__nav {
  margin: 90px 0 40px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.inner-info__nav-item + .inner-info__nav-item {
  margin-left: 55px; }

.inner-info__nav-item svg {
  width: 75px;
  height: 40px;
  fill: #e0e2e4; }

.inner-info__nav-item span {
  display: block;
  font-size: 0.8125rem;
  color: #a9b7c0;
  text-align: center;
  opacity: 0; }

.inner-info__nav-item--back svg {
  width: 55px; }

.inner-info__content {
  color: #404548;
  font-size: 1rem;
  line-height: 1.75; }
  .inner-info__content a {
    text-decoration: underline;
    font-weight: 700; }
  .inner-info__content h1, .inner-info__content h2, .inner-info__content h3, .inner-info__content h4, .inner-info__content h5, .inner-info__content h6 {
    color: #00c7d2;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 1.875rem; }
  .inner-info__content h1 {
    font-weight: 800;
    font-size: 2.375rem;
    margin-bottom: 50px; }
  .inner-info__content div {
    margin-bottom: 35px; }
  .inner-info__content .inserted-file {
    padding: 10px 0; }
    .inner-info__content .inserted-file .fa {
      margin-right: 20px; }

.inner-info__bottom {
  display: flex;
  align-items: center; }

.inner-info__date {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1;
  color: #d8dfe3;
  display: flex;
  align-items: flex-start;
  padding-right: 40px;
  margin-top: 50px; }
  .inner-info__date span {
    margin: 13px 0 0 10px;
    font-size: 20px;
    color: #6d7a82; }
  .inner-info__date + .inner-info__date {
    border-left: 2px solid #edeff0;
    padding-left: 40px;
    padding-right: 0; }

.product-details__top, .product-details__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px; }

.product-details__bottom {
  margin-bottom: 100px; }

.product-details__brand img {
  max-width: 150px; }

.product-details__image {
  float: left;
  margin: 0 60px 50px 0; }

.product-details__gallery {
  font-size: 1.25rem;
  font-weight: 700;
  color: #96a6ad;
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px; }
  .product-details__gallery a {
    border: 3px solid #f4f6f9;
    display: block;
    padding: 10px; }

.product-details__category {
  font-size: 1.625rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #00c7d2;
  margin-bottom: 55px;
  display: inline-block;
  text-decoration: none !important; }

.product-details__title {
  font-size: 2.375rem;
  font-weight: 800;
  color: #185478;
  margin-bottom: 40px; }

.product-details__description {
  color: #404548;
  line-height: 1.75; }
  .product-details__description > div {
    margin-bottom: 35px; }

.product-details__text a {
  text-decoration: underline;
  font-weight: 700; }

.product-details__text p {
  margin-bottom: 10px; }

.product-details__text h2, .product-details__text h3, .product-details__text h4, .product-details__text h5, .product-details__text h6 {
  color: #185478;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 1.875rem; }

.product-details__text .inserted-file {
  padding: 10px 0; }
  .product-details__text .inserted-file .fa {
    margin-right: 20px; }

.footer {
  position: relative;
  display: flex;
  font-weight: 700; }
  .footer__left {
    width: 35.5%;
    padding: 0 2.5rem 0 5.3125rem;
    background-color: #0f3e5a; }
  .footer__right {
    width: 64.5%;
    padding: 4.375rem 2.5rem 3.125rem 5rem;
    background-color: #0d354c;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; }
  .footer__gotop {
    position: absolute;
    bottom: 30px;
    right: 45px;
    color: #00c7d2;
    cursor: pointer; }
  .footer__title {
    margin-top: -4px;
    font-size: 4.375rem;
    line-height: 0.8;
    color: #fff;
    letter-spacing: -6px;
    text-transform: uppercase; }
  .footer__info {
    padding: 60px 0;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.875;
    text-align: left;
    color: #fff; }
  .footer__navigation {
    display: flex;
    justify-content: space-between;
    width: 60%; }
    .footer__navigation-group {
      display: flex;
      flex-direction: column;
      padding-right: 60px; }
    .footer__navigation-item {
      margin: 10px 5px;
      text-transform: uppercase;
      font-size: 1.25rem;
      color: #fff;
      display: inline-block; }
      .footer__navigation-item.active {
        color: #00c7d2; }
  .footer__social {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .footer__social-item {
      width: 40px;
      height: 40px;
      margin: 0 25px; }
      .footer__social-item:first-child {
        margin: 0 15px; }
      .footer__social-item svg {
        fill: #00c7d2; }
  .footer__allrights {
    min-width: 100%;
    margin-top: 4.6875rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: #81a9c0; }
    .footer__allrights a {
      color: #c7dbe7;
      font-weight: 700; }
  .footer__shape {
    width: 130px;
    height: 50px;
    bottom: 0;
    right: 120px; }

@media (min-width: 1440px) {
  .promotions__info {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .promotions__category {
    position: absolute;
    top: 65px;
    right: 6rem; }
  .promotions__item--type2 .promotions__category {
    top: 50px;
    right: auto; }
  .services__back {
    top: 50%;
    transform: translate(-50%, -50%); } }

@media (max-width: 1300px) {
  .services__head-title {
    font-size: 5rem; }
  .services__back {
    padding: 20px 35px 20px 20px; }
  .services__category {
    right: 10px; }
  .services__title {
    font-size: 1.3rem; } }

@media (max-width: 1200px) {
  .services__head-title {
    font-size: 4rem; }
  .services__back {
    padding: 10px 30px 10px 10px; }
  .services__title {
    font-size: 1.2rem; }
  .services__back-title {
    font-size: 1rem;
    margin-bottom: 5px; }
  .services__description {
    font-size: 0.875rem;
    margin-bottom: 5px; }
  .services__category {
    right: 5px; } }

@media (hover: hover) {
  .rev_slider_wrapper .tp-bullet:hover {
    background: url("../images/slider-bullet-active.svg") no-repeat center; }
  .header.active .header__button--menu:hover i {
    background: #00c7d2; }
  .header__button--menu:hover i {
    background: #00c7d2; }
  .header__button--menu:hover i:nth-of-type(2) {
    transition-duration: 200ms; }
  .header__button--menu:hover i:nth-of-type(3) {
    transition-duration: 400ms; }
  .main-nav__hidden-menu__item:hover {
    color: #00aeb8; }
  .main-nav__label:hover {
    color: #00aeb8; }
    .main-nav__label:hover i:before,
    .main-nav__label:hover i:after {
      background-color: #244356; }
  .menu-language__item:hover {
    color: #00c7d2; }
  .welcome__description a:hover {
    color: #00c7d2; }
  .services__item:hover .services__front {
    opacity: 0; }
  .services__item:hover .services__back {
    z-index: 2; }
  .services__item--no-image:hover {
    background-color: #fff; }
    .services__item--no-image:hover .services__back, .services__item--no-image:hover .services__description {
      color: #000; }
  .services__item--viewall:hover {
    background-color: #97a6ae; }
  .services--gallery .services__item:hover .services__front {
    background-color: #fff;
    background-image: none !important;
    opacity: 1; }
    .services--gallery .services__item:hover .services__front:before {
      display: none; }
  .services--gallery .services__item:hover .services__title {
    color: #182730; }
  .blog__category a:hover {
    color: #00c7d2; }
  .blog__item-title:hover {
    color: #557d95; }
  .blog .date-component__link:hover {
    color: #2c6d94; }
  .blog__item--akcent .blog__item-title:hover {
    color: #00bcc6; }
  .blog__item--akcent .date-component__link:hover {
    color: #009ba4; }
  .date-component__link:hover {
    color: #00c7d2; }
  .slick-dots > li:hover {
    background-image: url("../images/slider-bullet-active.svg"); }
  .promotions__title:hover, .promotions__link:hover {
    color: #fff; }
  .promotions__image:hover img {
    transform: scale(1.1); }
  .breadcrumbs__item:hover {
    color: #1b3f55; }
  .breadcrumbs--type2 .breadcrumbs__item:hover {
    color: #00c7d2; }
  .info-section__description a:hover {
    color: #00c7d2; }
  .info-section__back:hover {
    color: #00c7d2; }
    .info-section__back:hover i {
      border-color: #00c7d2; }
      .info-section__back:hover i:nth-of-type(3) {
        border-color: #ebeff4; }
  .info-section__filter-item:hover {
    color: #00c7d2; }
  .products__image:hover img {
    transform: scale(1.2); }
  .products__title:hover {
    color: #00c7d2; }
  .products--type2 .products__item--akcent .products__title:hover {
    color: #00bcc6; }
  .products--type2 .products__item--akcent .date-component__link:hover {
    color: #009ba4; }
  .btn:hover {
    background-color: #00c7d2;
    color: #fff; }
  .inner-info__nav-item:hover svg {
    fill: #00c7d2; }
  .inner-info__nav-item:hover span {
    opacity: 1; }
  .inner-info__content a:hover, .inserted-file:hover {
    color: #00c7d2; }
  .product-details__gallery a:hover {
    color: #fff;
    background-color: #00c7d2;
    border-color: #00c7d2; }
  .product-details__text a:hover {
    color: #00c7d2; }
  .footer__navigation-item:hover {
    color: #00c7d2; }
  .footer__social-item:hover svg {
    fill: #fff; }
  .footer__gotop:hover {
    color: #fff; }
  .footer__allrights a:hover {
    color: #fff; } }

@media (min-width: 1024px) {
  .services__item:nth-child(3n+1) {
    animation-delay: 200ms; }
    .services__item:nth-child(3n+1) .services__head-title, .services__item:nth-child(3n+1) .services__title {
      animation-delay: 400ms; }
  .services__item:nth-child(3n+2) {
    animation-delay: 400ms; }
    .services__item:nth-child(3n+2) .services__title {
      animation-delay: 600ms; }
  .services__item:nth-child(3n+3) {
    animation-delay: 600ms; }
    .services__item:nth-child(3n+3) .services__title {
      animation-delay: 800ms; }
  .products__item:nth-child(3n+1) {
    animation-delay: 200ms; }
  .products__item:nth-child(3n+2) {
    animation-delay: 400ms; }
  .products__item:nth-child(3n+3) {
    animation-delay: 600ms; } }

@media (max-width: 1024px) {
  html {
    font-size: 15px; }
  body.noScroll .header__inner {
    padding-right: 65px; }
  body.noScroll .header.fixed .header__inner {
    padding-right: 20px; }
  .header__menu-top {
    padding-top: 10px;
    padding-bottom: 10px; }
  .main-nav__label {
    font-size: 1.5rem; }
  .promotions__category {
    padding-right: 3rem; }
  .promotions__info {
    padding-left: 3rem;
    padding-right: 3rem; }
  .promotions__item--type2 .promotions__info, .promotions__item--type4 .promotions__info {
    padding-left: 3rem; }
  .promotions__item--type2 .promotions__category, .promotions__item--type4 .promotions__category {
    padding-left: 4rem; }
  .products--type2 .products__item {
    padding: 0 20px; }
  .blog {
    margin-top: 60px; }
  .form__input, .form__textarea {
    -webkit-appearance: none; } }

@media (max-width: 992px) {
  .wrapper {
    padding-left: 30px;
    padding-right: 30px; }
  .hamburger-icon {
    transform: scale(0.55); }
  body.noScroll .header__inner {
    padding-right: 20px; }
  .header.fixed .header__logo {
    width: 130px;
    height: 33px; }
  .header.fixed .header__inner {
    padding: 10px 20px; }
  .header.active .header__button {
    background-color: #fff; }
    .header.active .header__button span {
      display: none; }
  .header__inner {
    padding: 10px 20px; }
  .header__logo {
    height: 33px;
    width: 130px; }
  .header__menu {
    background: #fff; }
    .header__menu-top {
      padding: 10px 30px; }
    .header__menu-logo {
      width: 35px;
      height: 35px;
      margin-right: 40px; }
  .header__menu-nav {
    flex-direction: column; }
  .menu-language__item {
    font-size: 14px;
    margin: 5px; }
  .main-nav {
    width: 100%;
    padding: 0 30px 30px 30px; }
    .main-nav--secondary {
      background-color: #f0f3f4;
      padding-bottom: 50px; }
      .main-nav--secondary .main-nav__label {
        font-size: 15px; }
    .main-nav__item {
      margin-top: 30px; }
    .main-nav__label {
      font-size: 15px; }
    .main-nav__expand {
      min-width: 20px;
      height: 20px; }
    .main-nav__hidden-menu {
      padding-left: 30px; }
      .main-nav__hidden-menu__item {
        font-size: 14px;
        margin: 20px 20px 0 0; }
  .rev_slider_wrapper--innerpage .slotholder {
    height: 100vh;
    min-height: 500px; }
  .rev_slider_wrapper .section-shape {
    display: none; }
  .rev_slider_wrapper .tp-bullets {
    top: auto !important;
    left: 50% !important;
    bottom: 30px;
    transform: rotate(-90deg) translateX(-50%) !important;
    margin-left: -15px; }
  .rev_slider_wrapper .tp-bullet {
    transform: rotate(90deg); }
  .rev_slider_wrapper .main_caption_name {
    font-size: 28px !important;
    line-height: 1.3 !important;
    padding-right: 25px !important; }
    .rev_slider_wrapper .main_caption_name--subtitle {
      font-size: 20px !important; }
  .welcome {
    padding: 40px 0; }
    .welcome__logo {
      width: 80px;
      height: 80px;
      margin-left: auto;
      margin-right: auto; }
    .welcome__title {
      font-size: 36px;
      padding: 0;
      text-align: center; }
      .welcome__title span {
        font-size: 16px;
        padding-left: 80px; }
    .welcome__description {
      padding-left: 0;
      margin-top: 45px; }
    .welcome .section-shape--left {
      top: 60px;
      height: 140px;
      width: 100px; }
    .welcome .section-shape--right {
      display: none; }
  .services {
    padding: 0 30px 10px 30px; }
    .services__item {
      width: calc(50% - 10px);
      padding-bottom: calc(50% - 30px); }
    .services__title {
      font-size: 20px; }
  .main-paralax {
    padding: 30px; }
  .banner-wrapper .main-paralax:before {
    display: none; }
  .banner {
    padding: 40px 0; }
    .banner-wrapper__shape {
      display: none; }
      .banner-wrapper__shape--bottom {
        display: none; }
      .banner-wrapper__shape--mobile {
        left: auto;
        right: 0;
        transform: scaleX(-1);
        top: 350px;
        width: 145px;
        height: 290px;
        display: block; }
    .banner__shape {
      display: none; }
      .banner__shape--mobile {
        top: -6px;
        width: 260px;
        height: 270px;
        display: block; }
    .banner__image {
      height: 550px; }
    .banner__text {
      font-size: 44px;
      left: 30px;
      bottom: -3px; }
    .banner__description {
      position: static;
      padding: 70px 30px 30px 30px;
      max-width: 100%;
      position: relative;
      left: 0;
      bottom: auto; }
      .banner__description:before {
        display: none; }
      .banner__description:after {
        top: 100%;
        padding-bottom: 30%;
        height: auto; }
  .promotions {
    padding: 0; }
    .promotions__item {
      flex-direction: column; }
      .promotions__item--type2 .promotions__info, .promotions__item--type4 .promotions__info {
        order: 3; }
      .promotions__item--type2 .promotions__category, .promotions__item--type4 .promotions__category {
        padding-left: 0;
        text-align: right; }
    .promotions__image {
      width: 100%;
      padding: 0 30px;
      margin: 0;
      z-index: 2; }
    .promotions__info {
      width: 100%;
      margin: -35px 0 30px 0;
      padding: 55px 30px 30px 30px; }
    .promotions__shape {
      display: none; }
    .promotions__category {
      padding-right: 0;
      font-size: 16px; }
    .promotions__title {
      font-size: 22px; }
    .promotions__link {
      display: inline-block;
      margin-bottom: 10px; }
  .blog {
    padding: 80px 30px;
    overflow: hidden; }
    .blog__shape {
      top: 15px;
      right: -80px;
      width: 160px;
      height: 160px; }
    .blog__title {
      font-size: 38px;
      padding-right: 0; }
    .blog__category {
      padding-right: 0; }
    .blog__item {
      padding: 0; }
    .blog__item-title {
      font-size: 20px; }
  .date-component__date {
    font-size: 85px; }
    .date-component__date span {
      font-size: 19px; }
  .breadcrumbs {
    padding: 10px 0; }
    .breadcrumbs:after {
      display: none; }
    .breadcrumbs__item:first-child {
      margin-left: 0; }
  .inner-info__nav {
    margin: 40px 0 20px 0; }
    .inner-info__nav-item span {
      display: none; }
  .inner-info__content h1 {
    font-size: 22px;
    margin-bottom: 20px; }
  .inner-info__content h2, .inner-info__content h3, .inner-info__content h4, .inner-info__content h5, .inner-info__content h6 {
    font-size: 20px; }
  .inner-info__date {
    margin-top: 0;
    font-size: 36px;
    padding-right: 20px; }
    .inner-info__date + .inner-info__date {
      padding-left: 20px; }
    .inner-info__date span {
      margin: 5px 0 0 0px; }
  .gallery-block {
    padding: 40px 0; }
  .product-details__top {
    flex-direction: column;
    margin-bottom: 0; }
  .product-details__brand {
    order: 2; }
  .product-details__image {
    float: none;
    margin: 0 0 30px 0; }
  .product-details__category {
    font-size: 20px;
    margin-bottom: 30px; }
  .product-details__gallery a {
    font-size: 16px; }
  .product-details__title {
    font-size: 22px;
    margin-bottom: 20px; }
  .product-details__text h2, .product-details__text h3, .product-details__text h4, .product-details__text h5, .product-details__text h6 {
    font-size: 20px; }
  .product-details__bottom {
    flex-direction: column;
    margin-bottom: 30px; }
  .btn {
    width: 100%;
    text-align: center;
    padding: 20px; }
  .info-section {
    padding: 50px 0 30px 0; }
    .info-section__filter {
      margin: 40px 0 20px 0; }
      .info-section__filter-item {
        font-size: 16px;
        margin: 5px 10px; }
    .info-section__back {
      position: static;
      padding: 0 30px;
      justify-content: flex-end;
      margin-bottom: 30px; }
    .info-section__shape {
      top: 20px;
      left: -10px;
      width: 70px;
      height: 70px;
      bottom: auto; }
      .info-section__shape--inner {
        top: 0; }
      .info-section__shape--center {
        height: 100px;
        left: -5px;
        top: 30px; }
      .info-section__shape--center-text {
        margin-top: 0; }
      .info-section__shape--type5 {
        top: -75px; }
    .info-section__title {
      font-size: 22px;
      padding-left: 0;
      margin-bottom: 15px;
      margin-top: 50px; }
    .info-section__description {
      padding-left: 0; }
      .info-section__description--two-columns {
        flex-wrap: wrap; }
    .info-section__col {
      width: 100%;
      padding: 0; }
      .info-section__col:last-child {
        padding-left: 0;
        border-left: none; }
        .info-section__col:last-child article {
          margin-top: 20px; }
      .info-section__col article + article {
        margin-top: 20px; }
  .products__item {
    width: 50%; }
  .products--type2 {
    padding-left: 30px;
    padding-right: 30px; }
    .products--type2 .products__bottom {
      padding-right: 40px; }
    .products--type2 .products__item {
      margin-bottom: 75px; }
  .footer__left {
    padding-left: 2.2rem; }
  .footer__title {
    font-size: 3.5rem; }
  .footer__social {
    margin-top: 30px;
    width: 100%; } }

@media (max-width: 767px) {
  .rev_slider_wrapper .tp-caption {
    min-width: 300px !important; }
  .footer {
    flex-direction: column; }
    .footer__left, .footer__right {
      width: 100%;
      padding: 0 30px; }
    .footer__title {
      font-size: 44px; }
    .footer__info {
      font-size: 15px;
      padding: 40px 0; }
    .footer__right {
      padding-top: 25px;
      padding-bottom: 110px;
      flex-direction: column; }
    .footer__navigation {
      width: 100%; }
      .footer__navigation-group {
        padding-right: 25px; }
      .footer__navigation-item {
        font-size: 12px;
        margin-top: 12px;
        margin-bottom: 12px; }
    .footer__social {
      width: 100%;
      justify-content: center; }
      .footer__social-item {
        width: 33px;
        height: 33px;
        margin: 0 20px; }
    .footer__allrights {
      width: 100%;
      margin-top: 35px;
      text-align: center; }
    .footer__gotop {
      right: 35px;
      bottom: 65px; }
    .footer__shape {
      width: 110px;
      height: 44px;
      right: 10px; }
  .products {
    padding: 20px 25px; }
    .products--type2 .products__item {
      padding: 0;
      margin-bottom: 50px; }
    .products--type2 .products__bottom {
      padding-right: 0; }
    .products__item {
      width: 100%; }
    .products__category {
      font-size: 18px;
      padding-left: 10px; }
    .products__title, .products__description {
      padding: 0 10px; }
    .products__title {
      font-size: 20px; }
    .products__description {
      padding-right: 0;
      margin-bottom: 50px; }
  .date-component__date {
    font-size: 66px; }
    .date-component__date span {
      top: 20px;
      right: -30px;
      font-size: 17px; }
  .contacts {
    padding: 50px 0; }
    .contacts__form {
      margin: 25px 0 0 0; }
    .contacts__title {
      font-size: 1.5rem;
      padding: 0;
      margin-bottom: 20px; }
    .contacts__form {
      margin: 0; }
    .contacts__text {
      margin-top: 25px; }
  .form__row--two-columns {
    display: block; }
  .form__row--buttons {
    padding-left: 0; }
  .form__row .btn, .form__row .gcaptcha {
    margin-top: 20px; }
  .form__col {
    padding: 0; }
    .form__col-50 {
      width: 100%; }
      .form__col-50 + .form__col-50 {
        margin-top: 15px; }
  .form__input {
    font-size: 14px; } }

@media (max-width: 480px) {
  .rev_slider_wrapper .main_caption_name {
    font-size: 26px !important; }
  .services__item {
    width: 100%;
    padding-bottom: 100%;
    margin: 0 0 10px 0; }
  .form__row--buttons .form__col {
    flex-wrap: wrap; }
  .form__checkbox + .form__label {
    margin-left: 0; } }
